import {
  IForm,
  IOptions,
  IFormFields,
} from 'shared/services/api/FormService';
import {validateCPF} from 'validations-br';
import {format} from 'date-fns';
import * as Yup from 'yup';

import {IInputVisible} from 'pages/Form';
import stringCapitalize from '../stringCapitalize';

const setValidationErrors = (
  form: IForm[],
  inputVisible: IInputVisible,
  establishment: IOptions[],
  courseFiltered: IOptions[],
  cycleFiltered: IOptions[],
  shiftFiltered: IOptions[],
  complementos1DaOferta: IOptions[],
  complementos2DaOferta: IOptions[],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any => {
  let obj = {};
  let date: Date | string;
  let maxDate: Date | string;
  let minDate: Date | string;

  const handleOptPredefined = (field: IFormFields) => {
    switch (field.idCampoPredefinido) {
      case 2:
        return establishment;
      case 3:
        return courseFiltered;
      case 4:
        return cycleFiltered;
      case 5:
        return shiftFiltered;
      case 6:
        return complementos1DaOferta || [];
      case 7:
        return complementos2DaOferta || [];
      default:
        return field.opcoes;
    }
  };

  form?.map((item) => {
    item?.campos?.map((field) => {
      if (field.ativo && field.disponivelParaLead) {
        switch (field.idTipoDeCampo) {
          case 1: // SCHEDULER
            if (field.obrigatorio) {
              obj = {
                ...obj,
                [stringCapitalize(field.nomeCampo, field.idCampo)]:
                  Yup.string().required('Campo requerido.'),
              };
            } else {
              obj = {
                ...obj,
                [stringCapitalize(field.nomeCampo, field.idCampo)]:
                  Yup.string(),
              };
            }
            break;
          case 2: // CheckBox
            if (field.obrigatorio) {
              obj = {
                ...obj,
                [stringCapitalize(field.nomeCampo, field.idCampo)]:
                  Yup.string().required('Campo requerido.'),
              };
            } else {
              obj = {
                ...obj,
                [stringCapitalize(field.nomeCampo, field.idCampo)]:
                  Yup.string(),
              };
            }
            break;
          case 3: // DATE
            if (field.validacoes.length > 0) {
              field.validacoes.map((item) => {
                if (field.obrigatorio) {
                  switch (item.idCondicaoParaValidacao) {
                    case 14: // TODO: Não está entre
                      obj = {
                        ...obj,
                        [stringCapitalize(field.nomeCampo, field.idCampo)]:
                          Yup.date()
                            .required('Campo requerido.')
                            .min(new Date(item.validacaoFinal), item.mensagem)
                            .max(new Date(item.validacaoInicial), item.mensagem)
                            .typeError('Data inválida'),
                      };
                      break;
                    case 13: // Entre ------------------------------------------>
                      date = new Date(item.validacaoInicial);
                      minDate = format(
                        date.setDate(new Date(date).getDate()),
                        'yyyy-MM-dd',
                      );
                      obj = {
                        ...obj,
                        [stringCapitalize(field.nomeCampo, field.idCampo)]:
                          Yup.date()
                            .required('Campo requerido.')
                            .max(new Date(item.validacaoFinal), item.mensagem)
                            .min(new Date(minDate), item.mensagem)
                            .typeError('Data inválida'),
                      };
                      break;
                    case 12: // Diferente de ----------------------------------->
                      obj = {
                        ...obj,
                        [stringCapitalize(field.nomeCampo, field.idCampo)]:
                          Yup.date()
                            .required('Campo requerido.')
                            .test(
                              'equalsDate',
                              item.mensagem,
                              (date) =>
                                format(
                                  new Date(item.validacaoInicial),
                                  'yyyy-MM-dd',
                                ) ===
                                format(new Date(String(date)), 'yyyy-MM-dd'),
                            )
                            .typeError('Data inválida'),
                      };
                      break;
                    case 11: // Igual a ---------------------------------------->
                      obj = {
                        ...obj,
                        [stringCapitalize(field.nomeCampo, field.idCampo)]:
                          Yup.date()
                            .required('Campo requerido.')
                            .test(
                              'notEqualsDate',
                              item.mensagem,
                              (date) =>
                                format(
                                  new Date(item.validacaoInicial),
                                  'yyyy-MM-dd',
                                ) !==
                                format(new Date(String(date)), 'yyyy-MM-dd'),
                            )
                            .typeError('Data inválida'),
                      };
                      break;
                    case 10: // Menor ou igual a ------------------------------->
                      obj = {
                        ...obj,
                        [stringCapitalize(field.nomeCampo, field.idCampo)]:
                          Yup.date()
                            .required('Campo requerido.')
                            .max(
                              [new Date(item.validacaoInicial)],
                              item.mensagem,
                            )
                            .typeError('Data inválida'),
                      };
                      break;
                    case 9: // Menor que --------------------------------------->
                      date = new Date(item.validacaoInicial);
                      maxDate = format(
                        date.setDate(new Date(date).getDate() - 1),
                        'yyyy-MM-dd',
                      );
                      obj = {
                        ...obj,
                        [stringCapitalize(field.nomeCampo, field.idCampo)]:
                          Yup.date()
                            .required('Campo requerido.')
                            .max([maxDate], item.mensagem)
                            .typeError('Data inválida'),
                      };
                      break;
                    case 8: // Maior ou igual a -------------------------------->
                      date = new Date(item.validacaoInicial);
                      minDate = format(
                        date.setDate(new Date(date).getDate()),
                        'yyyy-MM-dd',
                      );
                      obj = {
                        ...obj,
                        [stringCapitalize(field.nomeCampo, field.idCampo)]:
                          Yup.date()
                            .required('Campo requerido.')
                            .min([minDate], item.mensagem)
                            .typeError('Data inválida'),
                      };
                      break;
                    case 7: // Maior que --------------------------------------->
                      date = new Date(item.validacaoInicial);
                      minDate = format(
                        date.setDate(new Date(date).getDate() + 1),
                        'yyyy-MM-dd',
                      );
                      obj = {
                        ...obj,
                        [stringCapitalize(field.nomeCampo, field.idCampo)]:
                          Yup.date()
                            .required('Campo requerido.')
                            .min([minDate], item.mensagem)
                            .typeError('Data inválida'),
                      };
                      break;
                    default:
                      obj = {
                        ...obj,
                        [stringCapitalize(field.nomeCampo, field.idCampo)]:
                          Yup.date()
                            .required('Campo requerido.')
                            .typeError('Data inválida'),
                      };
                      break;
                  }
                } else {
                  switch (item.idCondicaoParaValidacao) {
                    case 14: // Não está entre
                      obj = {
                        ...obj,
                        [stringCapitalize(field.nomeCampo, field.idCampo)]:
                          Yup.date().when(
                            stringCapitalize(field.nomeCampo, field.idCampo),
                            {
                              is: (value: string) => value?.length,
                              then: (rule) =>
                                rule
                                  .min(
                                    new Date(item.validacaoFinal),
                                    item.mensagem,
                                  )
                                  .max(
                                    new Date(item.validacaoInicial),
                                    item.mensagem,
                                  )
                                  .typeError('Data inválida'),
                            },
                          ),
                      };
                      break;
                    case 13: // Entre
                      date = new Date(item.validacaoInicial);
                      minDate = format(
                        date.setDate(new Date(date).getDate()),
                        'yyyy-MM-dd',
                      );
                      obj = {
                        ...obj,
                        [stringCapitalize(field.nomeCampo, field.idCampo)]:
                          Yup.date().when(
                            stringCapitalize(field.nomeCampo, field.idCampo),
                            {
                              is: (value: string) => value?.length,
                              then: (rule) =>
                                rule
                                  .max(
                                    new Date(item.validacaoFinal),
                                    item.mensagem,
                                  )
                                  .min(new Date(minDate), item.mensagem)
                                  .typeError('Data inválida'),
                            },
                          ),
                      };
                      break;
                    case 12: // Diferente de
                      obj = {
                        ...obj,
                        [stringCapitalize(field.nomeCampo, field.idCampo)]:
                          Yup.date().when(
                            stringCapitalize(field.nomeCampo, field.idCampo),
                            {
                              is: (value: string) => value?.length,
                              then: (rule) =>
                                rule
                                  .test(
                                    'notEqualsDate',
                                    item.mensagem,
                                    (date) =>
                                      date
                                        ? format(
                                            new Date(item.validacaoInicial),
                                            'yyyy-MM-dd',
                                          ) ===
                                          format(
                                            new Date(String(date)),
                                            'yyyy-MM-dd',
                                          )
                                        : true,
                                  )
                                  .typeError('Data inválida'),
                            },
                          ),
                      };
                      break;
                    case 11: // Igual a
                      obj = {
                        ...obj,
                        [stringCapitalize(field.nomeCampo, field.idCampo)]:
                          Yup.date().when(
                            stringCapitalize(field.nomeCampo, field.idCampo),
                            {
                              is: (value: string) => value?.length,
                              then: (rule) =>
                                rule
                                  .test(
                                    'notEqualsDate',
                                    item.mensagem,
                                    (date) =>
                                      date
                                        ? format(
                                            new Date(item.validacaoInicial),
                                            'yyyy-MM-dd',
                                          ) !==
                                          format(
                                            new Date(String(date)),
                                            'yyyy-MM-dd',
                                          )
                                        : true,
                                  )
                                  .typeError('Data inválida'),
                            },
                          ),
                      };
                      break;
                    case 10: // Menor ou igual a
                      obj = {
                        ...obj,
                        [stringCapitalize(field.nomeCampo, field.idCampo)]:
                          Yup.date().when(
                            stringCapitalize(field.nomeCampo, field.idCampo),
                            {
                              is: (value: string) => value?.length,
                              then: (rule) =>
                                rule
                                  .max(
                                    [new Date(item.validacaoInicial)],
                                    item.mensagem,
                                  )
                                  .typeError('Data inválida'),
                            },
                          ),
                      };
                      break;
                    case 9: // Menor que
                      date = new Date(item.validacaoInicial);
                      maxDate = format(
                        date.setDate(new Date(date).getDate() - 1),
                        'yyyy-MM-dd',
                      );
                      obj = {
                        ...obj,
                        [stringCapitalize(field.nomeCampo, field.idCampo)]:
                          Yup.date().when(
                            stringCapitalize(field.nomeCampo, field.idCampo),
                            {
                              is: (value: string | undefined) => value?.length,
                              then: (rule) =>
                                rule
                                  .max([maxDate], item.mensagem)
                                  .typeError('Data inválida'),
                            },
                          ),
                      };
                      break;
                    case 8: // Maior ou igual a
                      date = new Date(item.validacaoInicial);
                      minDate = format(
                        date.setDate(new Date(date).getDate()),
                        'yyyy-MM-dd',
                      );
                      obj = {
                        ...obj,
                        [stringCapitalize(field.nomeCampo, field.idCampo)]:
                          Yup.date().when(
                            stringCapitalize(field.nomeCampo, field.idCampo),
                            {
                              is: (value: string | undefined) => value?.length,
                              then: (rule) =>
                                rule
                                  .min([minDate], item.mensagem)
                                  .typeError('Data inválida'),
                            },
                          ),
                      };
                      break;
                    case 7: // Maior que
                      date = new Date(item.validacaoInicial);
                      minDate = format(
                        date.setDate(new Date(date).getDate() + 1),
                        'yyyy-MM-dd',
                      );
                      obj = {
                        ...obj,
                        [stringCapitalize(field.nomeCampo, field.idCampo)]:
                          Yup.date().when(
                            stringCapitalize(field.nomeCampo, field.idCampo),
                            {
                              is: (value: string) => value?.length,
                              then: (rule) =>
                                rule
                                  .min([minDate], item.mensagem)
                                  .typeError('Data inválida'),
                            },
                          ),
                      };
                      break;
                    default:
                      break;
                  }
                }
              });
            } else if (field.obrigatorio) {
              obj = {
                ...obj,
                [stringCapitalize(field.nomeCampo, field.idCampo)]: Yup.date()
                  .required('Campo requerido.')
                  .typeError('Campo requerido.'),
              };
            }
            // else {
            //   obj = {
            //     ...obj,
            //     [stringCapitalize(field.nomeCampo, field.idCampo)]:
            //       Yup.date().when(
            //         stringCapitalize(field.nomeCampo, field.idCampo),
            //         {
            //           is: (value: string) => value?.length,
            //           then: (rule) => rule.typeError('Data inválida'),
            //         },
            //       ),
            //   };
            // }
            break;
          case 5 || 6: // SELECT AND MULTI SELECT
            if (field.obrigatorio) {
              if (field.idCampoPredefinido === 4) {
                obj = {
                  ...obj,
                  [stringCapitalize(field.nomeCampo, field.idCampo)]:
                    handleOptPredefined(field).length > 0
                      ? Yup.string().required('Campo requerido')
                      : Yup.string(),
                };
              } else {
                obj = {
                  ...obj,
                  [stringCapitalize(field.nomeCampo, field.idCampo)]:
                    Yup.string().required('Campo requerido.'),
                };
              }
            } else {
              obj = {
                ...obj,
                [stringCapitalize(field.nomeCampo, field.idCampo)]:
                  Yup.string(),
              };
            }
            break;
          case 9 || 10: // TEXT AND TEXTAREA
            if (field.validacoes.length > 0) {
              field.validacoes.map((item) => {
                if (field.obrigatorio) {
                  if (item.idCondicaoParaValidacao) {
                    switch (item.idCondicaoParaValidacao) {
                      case 1: // CPF
                        obj = {
                          ...obj,
                          [stringCapitalize(field.nomeCampo, field.idCampo)]:
                            Yup.string()
                              .required('Campo requerido.')
                              .test('is-cpf', item.mensagem, (value) =>
                                value ? validateCPF(String(value)) : true,
                              ),
                        };
                        break;
                      case 2: // Email
                        obj = {
                          ...obj,
                          [stringCapitalize(field.nomeCampo, field.idCampo)]:
                            Yup.string()
                              .required('Campo requerido.')
                              .email(item.mensagem),
                        };
                        break;
                      case 3: // Maior que
                        obj = {
                          ...obj,
                          [stringCapitalize(field.nomeCampo, field.idCampo)]:
                            Yup.string()
                              .required('Campo requerido.')
                              .test('lenMax', item.mensagem, (value) =>
                                value
                                  ? String(value)?.length > 0 &&
                                    String(value)?.length >
                                      Number(item.validacaoInicial)
                                  : true,
                              ),
                        };
                        break;
                      case 4: // Menor que
                        obj = {
                          ...obj,
                          [stringCapitalize(field.nomeCampo, field.idCampo)]:
                            Yup.string()
                              .required('Campo requerido.')
                              .test('lenMin', item.mensagem, (value) =>
                                value
                                  ? String(value)?.length > 0 &&
                                    String(value)?.length <
                                      Number(item.validacaoInicial)
                                  : true,
                              ),
                        };
                        break;
                      default:
                        break;
                    }
                  } else {
                    obj = {
                      ...obj,
                      [stringCapitalize(field.nomeCampo, field.idCampo)]:
                        Yup.string().required('Campo requerido.'),
                    };
                  }
                } else {
                  switch (item.idCondicaoParaValidacao) {
                    case 1: // CPF
                      obj = {
                        ...obj,
                        cpfValidate: Yup.boolean(),
                        [stringCapitalize(field.nomeCampo, field.idCampo)]:
                          Yup.string().when('cpfValidate', {
                            is: (value: string | undefined) => !value,
                            then: (rule) =>
                              rule.test('is-cpf', item.mensagem, (value) =>
                                value ? validateCPF(String(value)) : true,
                              ),
                          }),
                      };
                      break;
                    case 2: // Email
                      obj = {
                        ...obj,
                        emailValidate: Yup.boolean(),
                        [stringCapitalize(field.nomeCampo, field.idCampo)]:
                          Yup.string().when('emailValidate', {
                            is: (value: string | undefined) => !value,
                            then: Yup.string().email(item.mensagem),
                          }),
                      };
                      break;
                    case 3: // Maior que
                      obj = {
                        ...obj,
                        [stringCapitalize(field.nomeCampo, field.idCampo)]:
                          Yup.string().when(
                            stringCapitalize(field.nomeCampo, field.idCampo),
                            {
                              is: (value: string) => value?.length,
                              then: (rule) =>
                                rule.test('lenMax', item.mensagem, (value) =>
                                  value
                                    ? String(value)?.length >
                                      Number(item.validacaoInicial)
                                    : true,
                                ),
                            },
                          ),
                      };
                      break;
                    case 4: // Menor que
                      obj = {
                        ...obj,
                        [stringCapitalize(field.nomeCampo, field.idCampo)]:
                          Yup.string().when(
                            stringCapitalize(field.nomeCampo, field.idCampo),
                            {
                              is: (value: string) => value?.length,
                              then: (rule) =>
                                rule.test('lenMax', item.mensagem, (value) =>
                                  value
                                    ? String(value)?.length <
                                      Number(item.validacaoInicial)
                                    : true,
                                ),
                            },
                          ),
                      };
                      break;
                    default:
                      break;
                  }
                }
              });
            } else if (field.obrigatorio) {
              obj = {
                ...obj,
                [stringCapitalize(field.nomeCampo, field.idCampo)]:
                  Yup.string().required('Campo requerido.'),
              };
            } else {
              obj = {
                ...obj,
                [stringCapitalize(field.nomeCampo, field.idCampo)]:
                  Yup.string(),
              };
            }
            break;
          case 4: // FILE
            if (Object.keys(inputVisible).length) {
              if (field.obrigatorio) {
                obj = {
                  ...obj,
                  [stringCapitalize(field.nomeCampo, field.idCampo)]:
                    inputVisible[field.idCampo].visible
                      ? Yup.string().required('Campo requerido.')
                      : Yup.string(),
                };
              } else {
                obj = {
                  ...obj,
                  [stringCapitalize(field.nomeCampo, field.idCampo)]:
                    Yup.string(),
                };
              }
            }

            break;
          default:
            break;
        }
      }
    });
  });

  return obj;
};

export default setValidationErrors;
